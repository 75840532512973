import { Stack, Typography } from '@mui/material';
import React from 'react';

export default function AuthTitle({
  title,
  subTitle,
}: {
  title: string;
  subTitle: string | React.ReactNode;
}) {
  return (
    <Stack spacing={2}>
      <Typography
        component="h2"
        sx={{
          textTransform: 'capitalize',
          fontSize: '32px',
          lineHeight: '36px',
        }}
      >
        {title}
      </Typography>
      <Typography sx={{ color: ({ palette }) => palette.gray['500'] }}>
        {subTitle}
      </Typography>
    </Stack>
  );
}
