'use client';
import React, { useState } from 'react';
import { useRouter } from 'nextjs-toploader/app';
import { signIn } from 'next-auth/react';
import * as yup from 'yup';
import { Stack, Typography } from '@mui/material';
import AuthTitle from '@/app/modules/auth/components/auth-title';
import TextInput from '@/app/modules/auth/components/text-input';
import PasscodeInput from '@/app/modules/auth/components/passcode-input';
import SubmitButton from '@/app/modules/auth/components/submit-button';
import Form from '@/app/modules/common/components/form';

const defaultValues = { username: '', password: '' };

interface LoginForm {
  username: string;
  password: string;
}

const schema = yup.object({
  username: yup
    .string()
    .trim()
    .email('Must be valid email')
    .required('Required Field'),
  password: yup.string().trim().required('Required Field'),
});

export default function SignInPageTemplate() {
  const router = useRouter();
  const [formStep, setFormStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const [credentials, setCredentials] = useState<LoginForm>();
  const [code, setCode] = useState<string[]>(Array(6).fill(''));

  const [error, setError] = useState('');

  const handleSubmit = async (form: LoginForm) => {
    const payload = {
      redirect: false,
      mfaCode: '',
      ...form,
    };

    setError('');
    setLoading(true);
    try {
      const res = await signIn('credentials', payload);

      if (res?.error) {
        if (res.error === 'MFA_REQUIRED') {
          setError('');
          setCredentials(form);
          setFormStep(1);
          return;
        }

        console.error(res.error);
        setError(
          res.error.toLowerCase().includes('incorrect')
            ? res.error
            : 'Form request error'
        );
        return;
      }
    } catch (e: any) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const submitCode = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const res = await signIn('credentials', {
        ...credentials,
        mfaCode: code.join(''),
        redirect: false,
      });

      if (res?.error) {
        setCode(Array(6).fill(''));
        setError('Code is incorrect');
        console.error(res.error);
        return;
      }

      router.push('/portal/overview');
    } catch (err) {
      setError('Request error');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {formStep === 0 && (
        <Form<LoginForm>
          onSubmit={handleSubmit}
          defaultValues={defaultValues}
          validation={schema}
        >
          <Stack spacing={2} sx={{ width: '387px' }}>
            <AuthTitle
              title="Sign In"
              subTitle="Sign in with your email and password"
            />
            <TextInput
              label="Email"
              name="username"
              placeholder="Enter Email"
            />
            <TextInput
              label="Password"
              name="password"
              type="password"
              placeholder="Enter Password"
              // headerChildren={
              //   <Link
              //     style={{
              //       color: '#0F0C85',
              //       fontSize: '16px',
              //       lineHeight: '24px',
              //     }}
              //     href={{ pathname: '/auth/forgot-password' }}
              //   >
              //     Forgot password?
              //   </Link>
              // }
            />
            {error && (
              <Typography sx={{ color: '#E20202', fontSize: '12px' }}>
                {error}
              </Typography>
            )}
            <SubmitButton disabled={loading} loading={loading}>
              Sign In
            </SubmitButton>
          </Stack>
        </Form>
      )}
      {formStep === 1 && (
        <form onSubmit={submitCode}>
          <Stack spacing={2} sx={{ width: '387px' }}>
            <AuthTitle
              title="Enter Passcode"
              subTitle="Please enter the code from your passcode app"
            />
            <PasscodeInput
              length={6}
              value={code}
              onChange={setCode}
              error={!!error}
            />
            {error && (
              <Typography sx={{ color: '#E20202', fontSize: '12px' }}>
                {error}
              </Typography>
            )}
            <SubmitButton disabled={loading} loading={loading}>
              Sign In
            </SubmitButton>
          </Stack>
        </form>
      )}
    </>
  );
}
