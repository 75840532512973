import React, { useState } from 'react';
import {
  IconButton,
  InputAdornment,
  InputLabel,
  Stack,
  OutlinedInput as MuiTextField,
  Typography,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useFormContext, Controller } from 'react-hook-form';

const StyledInput = styled(MuiTextField)(({ error }) => ({
  fontSize: '16px',
  borderRadius: '12px',
  border: 'unset',
  '.MuiOutlinedInput-input': {
    padding: '14px',
  },
  'Mui-focused.MuiOutlinedInput-notchedOutline': {
    borderColor: '#0F0C85 !important',
  },
  fieldset: {
    borderColor: error ? '#E20202 !important' : 'transparent',
  },
}));

interface TextInputProps {
  type?: string;
  label: string;
  name: string;
  headerChildren?: React.ReactNode;
  placeholder?: string;
}

export default function TextInput({
  type = 'text',
  label,
  headerChildren,
  name,
  placeholder,
}: TextInputProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Stack spacing={0.25}>
      <Stack direction="row" justifyContent="space-between">
        <InputLabel
          sx={{
            color: ({ palette }) => palette.primary.main,
            fontSize: '16px',
            lineHeight: '24px',
          }}
          htmlFor={`${name}-input`}
        >
          {label}
        </InputLabel>
        {headerChildren}
      </Stack>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <StyledInput
            {...field}
            id={`${name}-input`}
            type={showPassword || type === 'text' ? 'text' : 'password'}
            error={!!errors[name]}
            placeholder={placeholder}
            sx={{
              color: ({ palette }) => palette.primary.main,
            }}
            endAdornment={
              type === 'password' ? (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ) : undefined
            }
          />
        )}
      />
      {errors[name] && (
        <Typography variant="caption" sx={{ color: '#E20202' }}>
          {errors[name]?.message as string}
        </Typography>
      )}
    </Stack>
  );
}
