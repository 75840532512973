import React, { useRef, ChangeEvent, KeyboardEvent, useEffect } from 'react';
import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledInput = styled('input')(({ error }: { error: any }) => ({
  width: '52px',
  height: '52px',
  textAlign: 'center',
  fontSize: '16px',
  borderRadius: '12px',
  outline: 'none',
  border: error ? '1.6px solid #E20202' : 'unset',
  '&:focus': {
    border: '1.6px solid #0F0C85',
  },
}));

interface PasscodeInputProps {
  length?: number;
  onChange: (value: string[]) => void;
  value: string[];
  error?: boolean;
}

const PasscodeInput: React.FC<PasscodeInputProps> = ({
  length = 6,
  onChange,
  value,
  error,
}) => {
  const inputsRef = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    if (inputsRef.current[0]) inputsRef.current[0].focus();
  }, []);

  const handleChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const { value: targetValue } = e.target;
    const newValues = [...value];

    if (targetValue.length > 1) {
      newValues[index] = targetValue.slice(0, 1); // Only take the first character
    } else {
      newValues[index] = targetValue;
    }

    onChange(newValues);

    // Move to the next input if it's not the last one
    if (targetValue && index < length - 1) {
      inputsRef.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace' && !value[index] && index > 0) {
      inputsRef.current[index - 1]?.focus();
    }
  };

  const handleFocus = (index: number) => {
    inputsRef.current[index]?.select();
  };

  return (
    <Stack
      direction="row"
      alignContent="center"
      justifyContent="center"
      spacing={2}
    >
      {value.map((val, index) => (
        <StyledInput
          key={index}
          type="text"
          value={val}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onFocus={() => handleFocus(index)}
          ref={(el) => {
            inputsRef.current[index] = el;
          }}
          maxLength={1}
          className="passcode-input"
          error={error}
        />
      ))}
    </Stack>
  );
};

export default PasscodeInput;
