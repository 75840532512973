import {
  useForm,
  SubmitHandler,
  FieldValues,
  DefaultValues,
  FormProvider,
  Resolver,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'yup';

interface FormProps<T extends FieldValues> {
  onSubmit: SubmitHandler<T>;
  children: React.ReactNode;
  defaultValues: DefaultValues<T>;
  validationMode?: 'onSubmit' | 'onBlur' | 'onChange' | undefined;
  reValidationMode?: 'onSubmit' | 'onBlur' | 'onChange' | undefined;
  validation?: yup.ObjectSchema<T> | undefined;
  id?: string;
}

function Form<T extends FieldValues>({
  children,
  onSubmit,
  defaultValues,
  id,
  validationMode = 'onSubmit',
  reValidationMode = 'onSubmit',
  validation,
}: FormProps<T>) {
  const methods = useForm<T>({
    defaultValues,
    mode: validationMode,
    reValidateMode: reValidationMode,
    resolver: validation
      ? (yupResolver(validation) as unknown as Resolver<T>)
      : undefined,
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} id={id}>
        {children}
      </form>
    </FormProvider>
  );
}

export default Form;
